export default function DatasetCatalogView(props) {
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar primarybg-nav w-nav"
      >
        <div className="nav-wrapper primary">
          <a href="/" className="brand-link w-nav-brand">
            <img
              src="images/ai_logo_white.png"
              loading="lazy"
              srcSet="
              images/ai_logo_white-p-500.png   500w,
              images/ai_logo_white-p-800.png   800w,
              images/ai_logo_white-p-1080.png 1080w,
              images/ai_logo_white.png        1587w
            "
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 148.6750030517578px, 15vw"
              alt=""
              className="navlogo mob"
            />
          </a>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <a href="/" className="nav-link white-nav-link w-nav-link">
              Home
            </a>
            <a href="/#" className="nav-link white-nav-link w-nav-link">
              Products
            </a>
            <a href="about-us.html" className="nav-link white-nav-link w-nav-link">
              Team
            </a>
            <a href="events.html" className="nav-link white-nav-link w-nav-link">
              Events
            </a>
            <a href="/#" className="nav-link white-nav-link w-nav-link">
              Blog
            </a>
            <a href="/#" className="primary-btn contact-btn w-button">
              Contact Us
            </a>
          </nav>
        </div>
        <div className="menu-button w-nav-button">
          <div className="w-icon-nav-menu"></div>
        </div>
        <a href="/" className="mobile-logo w-nav-brand">
          <img
            src="images/ai_logo_white.png"
            loading="lazy"
            srcSet="
            images/ai_logo_white-p-500.png   500w,
            images/ai_logo_white-p-800.png   800w,
            images/ai_logo_white-p-1080.png 1080w,
            images/ai_logo_white.png        1587w
          "
            sizes="(max-width: 479px) 100vw, (max-width: 767px) 31vw, 100vw"
            alt=""
            className="navlogo mob"
          />
        </a>
      </div>
      <div className="section-9 wf-section">
        <h1 className="heading-17">Datasets</h1>
      </div>
      <div className="w-layout-grid datasets-overview">
        <div className="left-panel wf-section">
          <div className="ds-accordion">
            <div className="acc-header">
              <img
                src="images/Arrow.png"
                loading="lazy"
                width="20"
                height="12"
                alt=""
                className="image-29"
              />
              <div className="text-block-15">Region</div>
            </div>
            <div className="ds-filter">
              <img
                src="images/Vector.png"
                loading="lazy"
                height="15"
                width="15"
                alt=""
                className="image-26"
              />
              {/* <div className="ds-filterinput">Find a country</div> */}
              <input
                type="text"
                placeholder="Find a country"
                className="ds-filterinput"
                onChange={(e) => {
                  props.setRegionsFilter(e.target.value);
                }}
              />
            </div>
            <div className="checkbox-container">
              <div className="form-block-2 w-form">
                <form
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-2"
                >
                  {props.regions.map((region, i) => (
                    <label className="w-checkbox" key={i}>
                      <input
                        type="checkbox"
                        onChange={(e) => {}} // Todo
                        // name="United-States-of-America"
                        // data-name="United States of America"
                        className="w-checkbox-input"
                      />
                      <span
                        className="checkbox-label-2 w-form-label"
                      >
                        {region?.region}
                      </span>
                    </label>
                  ))}
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ds-accordion">
            <div className="acc-header">
              <img
                src="images/Arrow.png"
                loading="lazy"
                width="20"
                height="12"
                alt=""
                className="image-29"
              />
              <div className="text-block-15">Categories</div>
            </div>
            <div className="ds-filter">
              <img
                src="images/Vector.png"
                loading="lazy"
                height="15"
                width="15"
                alt=""
                className="image-26"
              />
              <div className="ds-filterinput">Find a country</div>
            </div>
            <div className="checkbox-container">
              <div className="form-block-2 w-form">
                <form
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-2"
                >
                  {props.categories.map((category, i) => (
                    <label className="w-checkbox" key={i}>
                      <input
                        type="checkbox"
                        name="United-States-of-America-2"
                        data-name="United States Of America 2"
                        className="w-checkbox-input"
                      />
                      <span
                        className="checkbox-label-2 w-form-label"
                      >
                        {category.category}
                      </span>
                    </label>
                  ))}
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ds-accordion">
            <div className="acc-header">
              <img
                src="images/Arrow.png"
                loading="lazy"
                width="20"
                height="12"
                alt=""
                className="image-29"
              />
              <div className="text-block-15">Licenses</div>
            </div>
            <div className="ds-filter">
              <img
                src="images/Vector.png"
                loading="lazy"
                height="15"
                width="15"
                alt=""
                className="image-26"
              />
              <div className="ds-filterinput">Find a country</div>
            </div>
            <div className="checkbox-container">
              <div className="form-block-2 w-form">
                <form
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-2"
                >
                  {props.licenses.map((license, i) => (
                    <label className="w-checkbox" key={i}>
                      <input
                        type="checkbox"
                        name="United-States-of-America-3"
                        data-name="United States Of America 3"
                        className="w-checkbox-input"
                      />
                      <span
                        className="checkbox-label-2 w-form-label"
                      >
                        {license.license}
                      </span>
                    </label>
                  ))}
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ds-accordion">
            <div className="acc-header">
              <img
                src="images/Arrow.png"
                loading="lazy"
                width="20"
                height="12"
                alt=""
                className="image-29"
              />
              <div className="text-block-15">Data Provider</div>
            </div>
            <div className="ds-filter">
              <img
                src="images/Vector.png"
                loading="lazy"
                height="15"
                width="15"
                alt=""
                className="image-26"
              />
              <div className="ds-filterinput">Find a country</div>
            </div>
            <div className="checkbox-container">
              <div className="form-block-2 w-form">
                <form
                  name="email-form"
                  data-name="Email Form"
                  method="get"
                  className="form-2"
                >
                  {props.dataProvider.map((dataProvider, i) => (
                    <label className="w-checkbox" key={i}>
                      <input
                        type="checkbox"
                        name="United-States-of-America-4"
                        data-name="United States Of America 4"
                        className="w-checkbox-input"
                      />
                      <span
                        className="checkbox-label-2 w-form-label"
                      >
                        {dataProvider.dataProvider}
                      </span>
                    </label>
                  ))}
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ds-grid wf-section"
        >
          <div className="search-panel">
            <div className="w-form">
              <form
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                className="seach-field"
              >
                <input
                  type="text"
                  className="search-field w-input"
                  maxLength="256"
                  name="name"
                  data-name="Name"
                  placeholder="search datasets by title or description"
                  onChange={(e) => {
                    props.setDatasetSearch(e.target.value);
                  }}
                />
                <a href="/#" className="search-icon w-inline-block">
                  <img
                    src="images/Vector.png"
                    loading="lazy"
                    alt=""
                    className="image-38"
                  />
                </a>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="filter-panel">
              <div className="text-block-22">25+ datasets</div>
              <div className="div-block-62">
                <img
                  src="images/Arrow.png"
                  loading="lazy"
                  alt=""
                  className="image-39"
                />
                <div className="text-block-33">1 filter applied</div>
                <a href="/#" className="clear-btn w-button">
                  Clear all
                </a>
              </div>
            </div>
          </div>
          <div className="w-layout-grid dataset-cards">
            {props.datasetCards.map((card, i) => (
              <div
                className="dataset-card"
                key={i}
              >
                <img
                  src={card.image}
                  loading="lazy"
                  width="407"
                  height="340"
                  sizes="(max-width: 479px) 92vw, 407px"
                  srcSet="
                images/Rectangle-406-p-500.png   500w,
                images/Rectangle-406-p-800.png   800w,
                images/Rectangle-406-p-1080.png 1080w,
                images/Rectangle-406.png        1439w
              "
                  alt=""
                  className="dataset-card-img"
                />
                <div className="dataset-card-content">
                  <h1 className="dataset-card-title">{card.title}</h1>
                  <p className="paragraph-15">{card.description}</p>
                  <div className="card-footer">
                    <div className="card-block"></div>
                    <div className="card-block-1"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section-13 wf-section">
        <div className="section-title">
          <h1 className="section-heading">Related Content</h1>
          <div className="section-divder"></div>
        </div>
        <div className="related-content-slider">
          <div className="rc-card">
            <img
              src="images/cat-img.svg"
              loading="lazy"
              width="108"
              height="130"
              alt=""
              className="image-34"
            />
            <div className="text-block-28">Lorem ipsum</div>
            <p className="paragraph-18">
              Lörem ipsum ponat dylig app för att ädyr. Eskade kyna. Tanera
              bening eftersom psykovalens fang. Lalig nynement.
            </p>
          </div>
          <div className="rc-card">
            <img
              src="images/cat-img.svg"
              loading="lazy"
              width="108"
              height="130"
              alt=""
              className="image-34"
            />
            <div className="text-block-28">Lorem ipsum</div>
            <p className="paragraph-18">
              Lörem ipsum ponat dylig app för att ädyr. Eskade kyna. Tanera
              bening eftersom psykovalens fang. Lalig nynement.
            </p>
          </div>
          <div className="rc-card">
            <img
              src="images/cat-img.svg"
              loading="lazy"
              width="108"
              height="130"
              alt=""
              className="image-34"
            />
            <div className="text-block-28">Lorem ipsum</div>
            <p className="paragraph-18">
              Lörem ipsum ponat dylig app för att ädyr. Eskade kyna. Tanera
              bening eftersom psykovalens fang. Lalig nynement.
            </p>
          </div>
        </div>
        <div className="slider-arrows">
          <img
            src="images/Vector-1.svg"
            loading="lazy"
            width="50"
            alt=""
            className="arrow-left"
          />
          <img
            src="images/Vector.svg"
            loading="lazy"
            width="50"
            alt=""
            className="arrow-right"
          />
        </div>
      </div>
      <div className="get-in-touch wf-section">
        <div className="get-in-touch-hero">
          <h1 className="heading-2">Get in Touch</h1>
          <div className="get-touch-desc">
            For all enquries please send us a message and our team will be happy
            to help you
          </div>
        </div>
        <div className="form-block w-form">
          <form
            name="email-form"
            data-name="Email Form"
            method="get"
            className="form"
          >
            <input
              type="text"
              className="text-field w-input"
              maxLength="256"
              name="first_name-2"
              data-name="First Name 2"
              placeholder="First name"
              required=""
            />
            <input
              type="text"
              className="text-field w-input"
              maxLength="256"
              name="last_name-2"
              data-name="Last Name 2"
              placeholder="Last name"
              required=""
            />
            <input
              type="email"
              className="text-field w-input"
              maxLength="256"
              name="email-2"
              data-name="Email 2"
              placeholder="email"
              required=""
            />
            <textarea
              placeholder="Leave us a message"
              maxLength="5000"
              name="field-3"
              data-name="field"
              className="text-field text-area w-input"
            ></textarea>
            <input
              type="submit"
              value="Submit"
              data-wait="Please wait..."
              className="primary-btn gt-submit w-button"
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      <section className="footer wf-section">
        <div className="footer-wrapper">
          <div className="footer-left-section">
            <div className="div-block-7">
              <a href="/#" className="footer-logo w-inline-block">
                <img
                  src="images/logo-light.png"
                  loading="lazy"
                  srcSet="
                  images/logo-light-p-500.png 500w,
                  images/logo-light.png       566w
                "
                  sizes="(max-width: 767px) 66vw, (max-width: 991px) 65vw, 20vw"
                  alt=""
                  className="image"
                />
              </a>
              <a href="/#" className="gt-link">
                Get in Touch
              </a>
            </div>
            <div>
              <div className="w-layout-grid grid-9">
                <a href="/#" className="footer-social-media-icon w-inline-block">
                  {" "}
                </a>
                <a href="/#" className="footer-social-media-icon w-inline-block">
                  {" "}
                </a>
              </div>
            </div>
            <div className="footer-social-block-three">
              <img
                src="images/Twitter.svg"
                loading="lazy"
                width="39"
                alt=""
                className="footer-social-media-icon"
              />
              <img
                src="images/linkedin.svg"
                loading="lazy"
                alt=""
                className="footer-social-media-icon"
              />
            </div>
          </div>
          <div className="footer-right-section">
            <div className="footer-form-two w-form">
              <form
                name="wf-form-sign-up-form"
                data-name="sign up form"
                method="get"
                className="sign-up-container"
              >
                <div className="footer-form-title">
                  Stay updated with our newsletter
                </div>
                <div className="footer-form-block-two">
                  <input
                    type="email"
                    className="subscribe-input w-input"
                    maxLength="256"
                    name="Footer-Email-Two-2"
                    data-name="Footer Email Two 2"
                    aria-label="Enter your email"
                    placeholder="Enter your email"
                    required=""
                  />
                  <input
                    type="submit"
                    data-wait="Please wait..."
                    value="Submit"
                    className="primary-btn footer-submit-btn w-button"
                  />
                </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="footer-links-block">
              <a href="/#" className="footer-link">
                Products
              </a>
              <a href="/#" className="footer-link">
                Plans
              </a>
              <a href="about-us.html" className="footer-link">
                About Us
              </a>
              <a href="/#" className="footer-link">
                Our Services
              </a>
              <a href="/#get-in-touch" className="footer-link">
                Blog
              </a>
              <a href="/#" className="footer-link mob">
                Events
              </a>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-bottom">
          <div className="footer-copyright mob">
            © 2022 COPYRIGHT advanced infrastructure. All rights reserved.
          </div>
          <div className="footer-legal-block">
            <a href="/#" className="footer-legal-link mob">
              Terms of Service
            </a>
            <a href="/#" className="footer-legal-link mob">
              Privacy Policy
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
