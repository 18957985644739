/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../utils";
import DatasetCatalogView from "./DatasetCatalog.view";

/**
 * 
 * @param {*} props Unused as of now
 * @returns DatasetCatalog Component
 */
export default function DatasetCatalogContainer(props) {
  const [regions, setRegions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [dataProvider, setDataProvider] = useState([]);
  const [regionFilter, setRegionFilter] = useState("");
  const [datasetSearch, setDatasetSearch] = useState("");
  const [datasetCards, setDatasetCards] = useState([]);

  useEffect(() => {
    getDatasets();
    getRegions();
    getCategories();
    getLicenses();
    getDataProviders();
  }, []);

  const getRegions = async () => {
    let _regions = [];
    const regionSnapshot = await getDocs(collection(firestore, "region"));
    regionSnapshot.forEach((doc) => {
      const _region = doc.data();
      _regions.push({ id: doc.id, region: _region?.region });
    });
    setRegions(_regions);
  };

  const getCategories = async () => {
    let _categories = [];
    const categorySnapshot = await getDocs(collection(firestore, "category"));
    categorySnapshot.forEach((doc) => {
      const _category = doc.data();
      _categories.push({ id: doc.id, category: _category?.category });
    });
    setCategories(_categories);
  };

  const getLicenses = async () => {
    let _licenses = [];
    const licenseSnapshot = await getDocs(collection(firestore, "license"));
    licenseSnapshot.forEach((doc) => {
      const _license = doc.data();
      _licenses.push({ id: doc.id, license: _license?.license });
    });
    setLicenses(_licenses);
  };

  const getDataProviders = async () => {
    let _dataProviders = [];
    const dataProviderSnapshot = await getDocs(
      collection(firestore, "dataProvider")
    );
    dataProviderSnapshot.forEach((doc) => {
      const _dataProvider = doc.data();
      _dataProviders.push({
        id: doc.id,
        dataProvider: _dataProvider?.dataProvider,
      });
    });
    setDataProvider(_dataProviders);
  };

  const getDatasets = async () => {
    let _datasetCards = [];
    const datasetSnapshot = await getDocs(collection(firestore, "dataset"));
    datasetSnapshot.forEach((doc) => {
      const _dataset = doc.data();
      _datasetCards.push({
        title: _dataset?.title,
        image: _dataset?.image,
        desc: _dataset?.desc,
        region: _dataset?.region,
        category: _dataset?.category,
        license: _dataset?.license,
        dataProvider: _dataset?.dataProvider,
      });
    });
    setDatasetCards(_datasetCards);
  };

  return (
    <div>
      <DatasetCatalogView
        regions={regions}
        setRegionFilter={setRegionFilter}
        categories={categories}
        licenses={licenses}
        dataProvider={dataProvider}
        setDatasetSearch={setDatasetSearch}
        datasetCards={datasetCards}
      />
    </div>
  );
}
