import { Routes, Route } from "react-router-dom";
import {
  DatasetCatalog,
  DatasetInfo,
  NotFound
} from "./pages";
import "./css/aitl.webflow.css";
import "./css/normalize.css";
import "./css/webflow.css";
// import "./App.css";

/**
 * App is the level-2 root component, under which all components are displayed according to their logic in a tree hierarchy structure.
 * Contains the following routes:
 *  1. / - Displays the list of datasets, and allows to filter the particular list.
 *  2. /:datasetID - Displays generic information about the dataset being queried.
 * 
 * @returns {Component}
 */
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<DatasetCatalog />} />
        <Route path="/:datasetID" element={<DatasetInfo />} />
        <Route path="*" element={NotFound} />
      </Routes>
    </div>
  );
}

export default App;
